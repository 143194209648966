import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      &copy; 2020 chuff-chuff Web
    </footer>
  )
}

export default Footer
